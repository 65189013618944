import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { mainNavigationIsNodeActiveSelector } from '../../../api/mainNavigation';

export interface MainNavigationContentItemProps extends React.HTMLAttributes<HTMLAnchorElement> {
  classes?: Partial<Record<MainNavigationContentItemClassKey, string>>;
  className?: string;
  icon?: React.ReactElement;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  to: string;
}

export type MainNavigationContentItemClassKey = 'root' | 'active' | 'content' | 'label' | 'icon';

const themeSettings = { name: 'MainNavigationContentItem' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<MainNavigationContentItemClassKey, {}>({
      root: {
        padding: theme.spacing(0.5, 2, 0.5, 2),
      },
      content: {
        width: '100%',
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
        backgroundColor: '#002969',
        color: '#FFFFFF',
        borderRadius: theme.shape.borderRadius,
      },
      active: {
        backgroundColor: theme.palette.mainNavigation.backgroundActive,
        color: theme.palette.text.primary,
      },
      label: {
        fontWeight: theme.typography.fontWeightMedium,
      },
      icon: {
        minWidth: 32,
        color: '#FFFFFF',
      },
    }),
  themeSettings,
);

const MainNavigationContentItem = React.forwardRef<HTMLAnchorElement, MainNavigationContentItemProps>(
  ({ className, classes: pClasses, icon, primary, secondary, to }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const match = useRouteMatch();

    const { active } = useRecoilValue(mainNavigationIsNodeActiveSelector({ nodePath: to, match }));

    return (
      <ListItem ref={ref} button className={clsx(classes.root, className)} component={RouterLink} to={to}>
        <div className={clsx(classes.content, { [classes.active]: active })}>
          {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
          <ListItemText classes={{ primary: classes.label }} primary={primary} secondary={secondary} />
        </div>
      </ListItem>
    );
  },
);

MainNavigationContentItem.displayName = 'MainNavigationContentItem';

export default withThemeProps(themeSettings)(MainNavigationContentItem);
