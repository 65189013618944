import cond from 'lodash/fp/cond';
import pathToRegexp from 'path-to-regexp';
import React from 'react';
import { match as Match, useRouteMatch } from 'react-router-dom';
import { atom, selectorFamily, useSetRecoilState } from 'recoil';
import { GlobalStateRegister } from '../@sprinx/react-after-razzle/stateStore';
import { pathToRouteUrlType } from '../routesBuilder';
import { localeState } from './appState';
import { productDetailQuery } from './products/productDetail';
import { catalogueTaxonomiesHierarchySelector, ProductTaxonomyTypeHierarchyNode } from './products/taxonomyTypes';

type SereializableMatch = {
  isExact: boolean;
  params: Record<string, string>;
  path: string;
  url: string;
};

export const mainNavigationMatchState = GlobalStateRegister.register(
  atom<Match<any>>({
    key: 'mainNavigationMatch',
    default: {
      isExact: true,
      params: {},
      path: '/',
      url: '/',
    },
  }),
);

export const mainNavigationIsNodeActiveSelector = selectorFamily<
  { active: boolean; last: boolean },
  { match: SereializableMatch; nodePath: string }
>({
  key: 'mainNavigationIsNodeActiveSelector',
  get:
    ({ nodePath, match }) =>
    ({ get }) => {
      const locale = get(localeState);
      const nodePathWithLocale = `/${locale}${nodePath}`;
      const re = pathToRegexp(match.path, undefined, { strict: match.isExact });
      const isMatch = re.exec(nodePathWithLocale) !== null;
      const routeUrlType = pathToRouteUrlType(match.path);

      if (routeUrlType === 'product') {
        const product = get(productDetailQuery(match.params.id));
        if (!product?.taxonomies)
          return {
            active: false,
            last: false,
          };

        const catalogTaxonomies = product.taxonomies.filter((taxonomy) => taxonomy.startsWith('/catalogue'));
        const longestTaxonomy = catalogTaxonomies[catalogTaxonomies.length - 1];
        const last = longestTaxonomy === nodePath;
        const active = longestTaxonomy.startsWith(nodePath);

        return {
          active,
          last,
        };
      }

      const last = match.url === nodePathWithLocale;

      if (nodePath.startsWith('/catalogue')) {
        const active = isMatch && match.url.startsWith(nodePathWithLocale);

        return {
          active,
          last,
        };
      }

      const active = cond<string, boolean>([
        [(np) => np.startsWith(`${locale}/catalogue`), (np) => (isMatch ? match.url.startsWith(np) : false)],
        [() => true, () => isMatch],
      ])(nodePath);

      return {
        active,
        last,
      };
    },
});

/**
 * Return filterd fields
 */
export const mainNavigationNodeSelector = selectorFamily<ProductTaxonomyTypeHierarchyNode | null, string>({
  key: 'mainNavigationNode',
  get:
    (nodePath) =>
    async ({ get }) => {
      const hierarchy = get(catalogueTaxonomiesHierarchySelector);

      const walk = (
        root: ProductTaxonomyTypeHierarchyNode,
        targetNodeCode: string,
      ): ProductTaxonomyTypeHierarchyNode | null => {
        if (root.code === targetNodeCode) return root;
        if (root.children && root.children.length) {
          for (const child of root.children) {
            const res = walk(child, targetNodeCode);
            if (res) return res;
          }
        }
        return null;
      };

      const node = walk(hierarchy, nodePath);

      return node;
    },
});

export const createMainNavigationState = <P extends Record<string, any> = {}>(
  match: Match<P>,
): { presaveState: string[]; propagateToState: { mainNavigationMatch: Match<any> } } => ({
  presaveState: ['mainNavigationMatch'],
  propagateToState: { mainNavigationMatch: match },
});

export const useUpdateMainNavigationMatch = (): void => {
  const match = useRouteMatch();
  const setMainNavigationMatch = useSetRecoilState(mainNavigationMatchState);
  React.useEffect(() => {
    setMainNavigationMatch(match);
  }, [match, setMainNavigationMatch]);
};
