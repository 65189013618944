import { selector } from 'recoil';
import { ApiClient } from '../@sprinx/react-after-razzle';
import { apiClientState } from './appState';

export const getDeliveryPlaces = (apiClient: ApiClient): Promise<any> => {
  return apiClient.get('/v1/deliveryPlaces/buyer', {});
};

export const deliveryPlacesForSlave = selector<Array<any>>({
  key: 'deliveryPlacesForSlave',
  get: ({ get }) => {
    const apiClient = get(apiClientState);
    return getDeliveryPlaces(apiClient);
  },
});
