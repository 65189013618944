import { usePreferredLocale } from '@sprinx/react-globalize';
import { SupportedLocale } from '../i18n/types';
import { routeUrl, RouteUrlType } from '../routesBuilder';

type RouteUrlFactory = (
  type: RouteUrlType,
  options?: { otherParamsToQs?: boolean | string[]; params?: Record<string, string> },
) => string;

const useRouteUrlFactory = (): RouteUrlFactory => {
  const locale = usePreferredLocale() as SupportedLocale;

  return (
    type: RouteUrlType,
    options: {
      otherParamsToQs?: boolean | string[];
      params?: Record<string, string>;
    } = {},
  ) => {
    const { params, otherParamsToQs } = options;

    const url = routeUrl(type, {
      locale,
      params: params ?? {},
      otherParamsToQs,
    });

    return url;
  };
};

export default useRouteUrlFactory;
