import { atomFamily, selectorFamily } from 'recoil';
import { EntityId } from '../../@sprinx/knihovka-types/common';
import { CreditNote, CreditNoteRecord } from '../../@sprinx/knihovka-types/creditNotes';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export type CreditNoteDetailCreditNote = CreditNote<
  CreditNoteRecord<{ invoiceType?: 'CUSTORDCRE' | 'INSTINV' }>,
  'contact' | 'owner'
>;

export const getCreditNoteDetail = (
  apiClient: ApiClient,
  params: { id: EntityId; populate?: string[] },
): Promise<CreditNoteDetailCreditNote> => {
  return apiClient.get<CreditNoteDetailCreditNote, { id: EntityId }>('/v1/credit-notes/:id', params);
};

export const creditNoteDetailInitialStateFamily = GlobalStateRegister.register(
  atomFamily<CreditNoteDetailCreditNote | undefined, EntityId>({
    key: 'creditNoteDetailInitial',
    default: undefined,
  }),
  'creditNoteDetailInitial',
);

export const creditNoteDetailQuery = selectorFamily<CreditNoteDetailCreditNote, EntityId>({
  key: 'creditNoteDetail',
  get:
    (creditNoteId) =>
    ({ get }) => {
      const initial = get(creditNoteDetailInitialStateFamily(creditNoteId));
      if (initial) return initial;

      const apiClient = get(apiClientState);

      return getCreditNoteDetail(apiClient, { id: creditNoteId, populate: ['contact'] });
    },
});
