import Badge from '@material-ui/core/Badge';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartTwoTone';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { shoppingCartnumberOfSubjectsSelector } from '../../api/shoppingCart';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';

export interface ShoppingCartAppBarActionProps extends Omit<IconButtonProps<RouterLink>, 'classes' | 'to'> {
  classes?: Partial<Record<ShoppingCartAppBarActionClassKey, string>>;
}

export type ShoppingCartAppBarActionClassKey = 'root';

const themeSettings = { name: 'ShoppingCartAppBarAction' };
const useStyles = makeStyles(
  createStyles<ShoppingCartAppBarActionClassKey, {}>({
    root: {},
  }),
  themeSettings,
);

const ShoppingCartAppBarAction = React.forwardRef<HTMLAnchorElement, ShoppingCartAppBarActionProps>(
  ({ className, classes: pClasses, ...rest }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const subjectsNumber = useRecoilValue(shoppingCartnumberOfSubjectsSelector);
    const routeUrlFactory = useRouteUrlFactory();

    return (
      <IconButton
        ref={ref}
        className={clsx(classes.root, className)}
        {...rest}
        component={RouterLink}
        to={routeUrlFactory('cartSummary')}
      >
        <Badge
          badgeContent={subjectsNumber || undefined}
          color='secondary'
          // anchorOrigin={{
          //   vertical: 'top',
          //   horizontal: 'left',
          // }}
        >
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
    );
  },
);

ShoppingCartAppBarAction.displayName = 'ShoppingCartAppBarAction';

export default withThemeProps(themeSettings)(ShoppingCartAppBarAction);
