import qs from 'qs';
import NotFoundErrorPage from './404';
import InternalErrorPage from './500';
import { replaceRouteParams } from './@sprinx/react-after-razzle';
import { routeAllRoutesToRouter } from './routesBuilder';

const linksMap = {
  catalogue: {
    cs: '/katalog',
  },
};

// function getRoutesDefinitionsForCode(
//   code: keyof typeof linksMap,
//   routeCfg: (path: string) => AsyncRouteProps<any>,
// ): AsyncRouteProps<any>[] {
//   return Object.entries(linksMap[code]).map(([, /* locale */ path]) => routeCfg(path));
// }

export function getRouteForCode(code: keyof typeof linksMap, locale: 'cs', params?: Record<string, any>): string {
  const pathProto = linksMap[code][locale];
  return !params
    ? pathProto
    : (() => {
        const [path, queryParams] = replaceRouteParams(pathProto, params);
        return !queryParams
          ? path
          : `${path}${Object.keys(queryParams).length ? qs.stringify(queryParams, { addQueryPrefix: true }) : ''}`;
      })();
}

export default [
  {
    path: '/en',
    exact: true,
    redirectTo: '/en/home',
  },
  {
    path: '/cs',
    exact: true,
    redirectTo: '/cs/home',
  },
  ...routeAllRoutesToRouter(),
  {
    path: '/_error',
    component: InternalErrorPage,
  },
  {
    component: NotFoundErrorPage,
  },
];
