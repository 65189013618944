import React from 'react';
import { Subtract } from 'utility-types';
import { Location } from 'history';
import { AfterCtx, AfterPagePropsWithoutError } from '../types';

export type MakeAfterComponentGetInitialProps<InitialProps extends object, M = any> = (
  ctx: AfterCtx<M>,
) => Promise<Partial<InitialProps> & { redirectTo?: string | Location }>;

export default function makeAfterComponent<
  InitialProps extends { error?: any },
  P extends AfterPagePropsWithoutError & InitialProps,
  M = any,
>(
  Component: React.ComponentType<P>,
  getInitialProps: MakeAfterComponentGetInitialProps<InitialProps, M>,
): React.ComponentType<Subtract<P, AfterPagePropsWithoutError & InitialProps>> {
  function AfterInitialPropsWrapper(
    props: Subtract<P, AfterPagePropsWithoutError & InitialProps>,
  ): React.ReactElement<any, any> | null {
    return <Component {...(props as P)} />;
  }

  AfterInitialPropsWrapper.displayName = `AfterInitialPropsWrapper(${
    Component.displayName || Component.name || 'Component'
  })`;
  AfterInitialPropsWrapper.getInitialProps = getInitialProps;

  return AfterInitialPropsWrapper;
}
