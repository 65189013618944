import { atom, selector, useRecoilValue } from 'recoil';
import {
  ContactCompany,
  ContactCompanyRecord,
  ContactOffice,
  ContactOfficeRecord,
} from '../@sprinx/knihovka-types/contacts';
import { GlobalStateRegister } from '../@sprinx/react-after-razzle/stateStore';
import { authState } from './appState';
import { ShoppingCartProduct } from './shoppingCart';

export const shoppingCustomerSelector = selector<ShoppingCustomerContact | undefined>({
  key: 'shoppingCustomer',
  get: ({ get }) => {
    const auth = get(authState);
    return auth?.profile?.customer;
  },
});
export const useShoppingCustomer = (): ShoppingCustomerContact | undefined => {
  return useRecoilValue(shoppingCustomerSelector);
};

export const shoppingSelectedDeliveryPlaceState = GlobalStateRegister.register(
  atom<ShoppingCustomerContact | undefined>({
    key: 'shoppingSelectedDeliveryPlace',
    default: undefined,
  }),
);

export const shoppingSelectedShippingState = GlobalStateRegister.register(
  atom<(ShoppingCartProduct & { allowedPaymentMethods?: string[] }) | undefined>({
    key: 'shoppingSelectedShipping',
    default: undefined,
  }),
);

export const shoppingSelectedPaymentState = GlobalStateRegister.register(
  atom<ShoppingCartProduct | undefined>({
    key: 'shoppingSelectedPayment',
    default: undefined,
  }),
);

export const shoppingIsDeliveryPlaceSelectedSelector = selector<boolean>({
  key: 'shoppingIsDeliveryPlaceSelectedSelector',
  get: ({ get }) => {
    return !!get(shoppingSelectedDeliveryPlaceState);
  },
});

export const shoppingIsShippingSelectedSelector = selector<boolean>({
  key: 'shoppingIsShippingSelectedSelector',
  get: ({ get }) => {
    return !!get(shoppingSelectedShippingState);
  },
});

export const shoppingCustomerDeliveryPlacesSelector = selector({
  key: 'shoppingCustomerDeliveryPlaces',
  get: ({ get }) => {
    const auth = get(authState);
    return auth?.profile?.deliveryPlaces?.filter((p) => !p?.disabled);
  },
});

export const shoppingCustomerDeliveryPlacesAllSelector = selector({
  key: 'shoppingCustomerDeliveryPlacesAll',
  get: ({ get }) => {
    const auth = get(authState);
    return auth?.profile?.deliveryPlaces;
  },
});

export const shoppingEnabledDynamicPricingRulesState = atom<string[] | undefined>({
  key: 'shoppingEnabledDynamicPricingRules',
  default: undefined,
});

export type ShoppingCustomerContact =
  | ContactCompany<
      ContactCompanyRecord<ShoppingCustomerContactExtra>,
      never,
      | 'id'
      | 'name'
      | 'type'
      | 'avatar'
      | 'extra'
      | 'contactGroup'
      | 'priceGroup'
      | 'postaddresses'
      | 'regNo'
      | 'vatNo'
      | 'currency'
    >
  | ContactOffice<
      ContactOfficeRecord<ShoppingCustomerContactExtra>,
      never,
      | 'id'
      | 'name'
      | 'type'
      | 'avatar'
      | 'extra'
      | 'contactGroup'
      | 'priceGroup'
      | 'postaddresses'
      | 'parentCompany'
      | 'currency'
    >;

/*export interface DeliveryType {
  displayName: DeliveryTypeNameArray;
  externals: object;
  extra: DeliveryTypeExtra;
  listPrice: DeliveryTypePriceArray;
  listPriceWithTax: DeliveryTypePriceArray;
  name: DeliveryTypeNameArray;
  region: string;
  sku: string;
  taxes: object;
}

export interface DeliveryTypeExtra {
  code: string;
}

export type DeliveryTypeNameArray = Array<DeliveryTypeName>;

export interface DeliveryTypeName {
  language: string;
  text: string;
}

export type DeliveryTypePriceArray = Array<DeliveryTypePrice>;

export interface DeliveryTypePrice {
  amount: number;
  currency: string;
}*/
export interface ShoppingCustomerContactExtra {
  customerDetailedRegion?: string;
  customerNo?: string;
  customerRegion?: string;
  locationDelivery?: string;
  maturityPeriod: string;
  paymentType: string;
  postaddresses?: string;
  priceLevel?: number;
  priceLevelName?: string;
  regNo?: string;
  responsiblePersonName: string;
  salesman?: string;
  taxNo?: string;
  xmlFeedHash?: string | null;
}
