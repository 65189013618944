import { QueryOrderSettings, QueryPaginationSettings, TableFilterField } from '@sprinx/query-builder';
import { atom, DefaultValue, selector } from 'recoil';
import { MoleculerListResult, Order, OrderRecord } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import {
  buildListQuery,
  createListInitialSettings,
  ListCallParams,
} from '../../@sprinx/react-after-razzle/filteredLists';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, localeState } from '../appState';

export type OrderListItem = Order<OrderRecord, 'contact' | 'fulfillments'>;

export const ordersQuery = selector<MoleculerListResult<OrderListItem>>({
  key: 'orders',
  get: ({ get }) => {
    const initialState = get(ordersInitialState);
    if (initialState) {
      return initialState;
    }

    const apiClient = get(apiClientState);
    const locale = get(localeState);
    const params = get(ordersCallParamsState);
    get(ordersReloadTrigger);

    return getOrders(apiClient, locale, params);
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(ordersReloadTrigger, (n) => n + 1);
    }
  },
});

export const ordersCallParamsState = GlobalStateRegister.register(
  atom<ListCallParams>({
    key: 'ordersCallParams',
    default: createListInitialSettings({
      rowsPerPage: 10,
      orderBy: 'number',
      orderDirection: 'desc',
    }),
  }),
);

export const ordersOrderByState = selector<QueryOrderSettings>({
  key: 'ordersOrderBy',
  get: ({ get }) => {
    const params = get(ordersCallParamsState);
    return params.order;
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(ordersCallParamsState, (prev) => ({
        ...prev,
        order: newValue,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const ordersPaginationState = selector<QueryPaginationSettings & { total: number }>({
  key: 'ordersPagination',
  get: ({ get }): QueryPaginationSettings & { total: number } => {
    const params = get(ordersCallParamsState);
    const queryResult = get(ordersQuery);

    return {
      page: queryResult.page || params.pagination.rowsPerPage,
      rowsPerPage: queryResult.pageSize || params.pagination.rowsPerPage,
      total: queryResult.total,
    };
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(ordersCallParamsState, (prev) => ({
        ...prev,
        pagination: {
          // ...prevValue.pagination,
          ...newValue,
        },
      }));
    }
  },
});

export const ordersFilterState = selector<TableFilterField<any>[]>({
  key: 'ordersFilter',
  get: ({ get }) => {
    const params = get(ordersCallParamsState);
    return (params.filter as TableFilterField<any>[]) || [];
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const nextFilter = newValue.filter(Boolean);

      set(
        ordersCallParamsState,
        (prev): ListCallParams => ({
          ...prev,
          filter: nextFilter,
          pagination: {
            ...prev.pagination,
            page: 1,
          },
        }),
      );
    }
  },
});

export const ordersInitialState = GlobalStateRegister.register(
  atom<MoleculerListResult<OrderListItem> | undefined>({
    key: 'ordersInitialState',
    default: undefined, // createListDefaultResult<OrdersProduct>(),
  }),
);

export function getOrders(
  apiClient: ApiClient,
  locale: string,
  params: ListCallParams,
): Promise<MoleculerListResult<OrderListItem>> {
  return apiClient.get<MoleculerListResult<Omit<OrderListItem, 'href'>>, Record<string, any>>(
    '/v1/orders',
    buildListQuery(params),
  );
}

export const ordersReloadTrigger = atom<number>({
  key: 'ordersReloadTrigger',
  default: 0,
});
