import { DEFAULT_LOCALE } from '../../i18n/constants';
import { SupportedLocale } from '../../i18n/types';
import { routeUrl } from '../../routesBuilder';
import createInitialPropsRedirectToLogin from './createInitialPropsRedirectToLogin';
import { MakeAfterComponentGetInitialProps } from './makeAfterComponent';
import { AfterCtx } from './types';

function preventAuth<InitialProps extends object, M = any>(
  getInitialProps?: MakeAfterComponentGetInitialProps<InitialProps, M>,
): (ctx: AfterCtx<M>) => ReturnType<MakeAfterComponentGetInitialProps<InitialProps, M>> {
  return (ctx: AfterCtx<M>) => {
    const appProps = ctx.getAppProps ? ctx.getAppProps() : ctx.initialAppProps;
    const locale = ((ctx?.match?.params as any)?.locale as SupportedLocale) ?? DEFAULT_LOCALE;
    // Redirect to login if not authenticated
    if (!appProps.auth || !appProps.auth.isAuthenticated) {
      return Promise.resolve(createInitialPropsRedirectToLogin(ctx, routeUrl('login', { locale, params: {} as any })));
    }
    return getInitialProps ? getInitialProps(ctx) : Promise.resolve({});
  };
}

export default preventAuth;
